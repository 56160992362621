import React from "react"
import { Link } from "gatsby"
import slugify from "../../../helpers/slugify"
import { Box, Heading, Image, Text } from "theme-ui"
import moment from "moment"
import cloudinaryOptimize from "../../../helpers/cloudinaryOptimize"

export default function OtherArticles({
  title,
  allArticlesData,
  currentArticleId,
  variant,
  pageObjectKey,
}) {
  const filteredArray = allArticlesData.filter(
    article => article.id !== currentArticleId
  )
  return (
    <Box sx={variant.otherArticlesContainer} className="otherArticlesContainer">
      <Heading
        as="h3"
        sx={variant.otherArticleHeading}
        className="otherArticleHeading"
      >
        {title ? title : "Other Articles"}
      </Heading>
      <Box sx={variant.otherArticles} className="otherArticles">
        {filteredArray.map(article => (
          <Link
            to={`/${pageObjectKey}/${slugify(
              article.title ? article.title : ""
            )}`}
          >
            <Box sx={variant.otherArticle} className="otherArticle">
              {article?.media?.[0]?.cloudinaryId && (
                <Image
                  sx={variant.otherArticleImage}
                  className="otherArticleImage"
                  src={cloudinaryOptimize(
                    `https://res.cloudinary.com/gonation/${article?.media?.[0]?.cloudinaryId}`,
                    200
                  )}
                />
              )}
              <Box
                sx={variant.otherArticleContent}
                className="otherArticleContent"
              >
                <Heading
                  as="h5"
                  sx={variant.otherArticleTitle}
                  className="otherArticleTitle"
                >
                  {article.title}
                </Heading>
                <Heading
                  as="h6"
                  sx={variant.otherArticleSubtitle}
                  className="otherArticleSubtitle"
                >
                  {article.subtitle}
                </Heading>
                <Text
                  as="p"
                  sx={variant.otherArticleDate}
                  className="otherArticleDate"
                >
                  {moment(article.createdAt).format("DD MMM, YYYY")}
                </Text>
              </Box>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  )
}
