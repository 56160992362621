import React, { useState, useEffect } from "react"
import { Box, Heading, Image, Text, Link } from "theme-ui"
// import { Link } from "gatsby"
// import slugify from "slugify"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import moment from "moment"
import teamStyles from "../../styles/articles/teamPageStyles"
import generalStyles from "../../styles/articles/generalPageStyles"
import pressStyles from "../../styles/articles/pressPageStyles"
import policiesStyles from "../../styles/articles/policiesPageStyles"
import blogStyles from "../../styles/articles/blogPageStyles"
import Tags from "./ArticleComponents/Tags"
import OtherArticles from "./ArticleComponents/OtherArticles"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import cloudinaryOptimize from "../../helpers/cloudinaryOptimize"

export default function ArticlePage({
  articleData,
  pageObjectKey,
  allArticlesData,
}) {
  const styleSelector = () => {
    switch (pageObjectKey) {
      case "blog":
        return blogStyles

      case "team":
        return teamStyles

      case "press":
        return pressStyles

      case "general":
        return generalStyles

      case "policies":
        return policiesStyles

      default:
        break
    }
  }

  const articleInfo = () => (
    <>
      {articleData.author && (
        <Text as="p" className="author" sx={variant.author}>
          By {articleData.author}
        </Text>
      )}

      <Box sx={variant.timeContainer} className="timeContainer">
        <Text sx={variant.readingTime} className="readingTime">
          <FontAwesomeIcon icon={faClock} /> {articleData.readingTimeText}
        </Text>
        <Text sx={variant.createdAt} className="createdAt">
          <FontAwesomeIcon icon={faCalendarAlt} />{" "}
          {moment(articleData.createdAt).format("DD MMM, YYYY")}
        </Text>
      </Box>
    </>
  )

  const OtherArticlesTitle = () => {
    switch (pageObjectKey) {
      case "blog":
        return "Other Articles"

      case "team":
        return "Other Team Members"

      case "press":
        return "In The Press"

      default:
        break
    }
  }

  const variant = styleSelector()

  return (
    <Box sx={variant.container}>
      <Box sx={variant.imageContainer}>
        <Slider {...settings}>
          {articleData?.media.map(image => {
            return (
              <Image
                sx={variant.image}
                src={cloudinaryOptimize(
                  `https://res.cloudinary.com/gonation/${image.cloudinaryId}`,
                  1200
                )}
              />
            )
          })}
        </Slider>
      </Box>

      <Box sx={variant.contentRow}>
        <Box sx={variant.column1}>
          <Box sx={variant.heading}>
            <Heading as="h3" className="title" sx={variant.title}>
              {articleData.title}
            </Heading>
            <Heading as="h4" className="subtitle" sx={variant.subtitle}>
              {articleData.subtitle}
            </Heading>
            {articleData.tags && (
              <Tags tags={articleData.tags} variant={variant} />
            )}
            {pageObjectKey !== "team" && (
              <Box sx={variant.articleInfoMobile}>{articleInfo()}</Box>
            )}
          </Box>
          <Box
            sx={variant.bodyContainer}
            dangerouslySetInnerHTML={{ __html: articleData.bodyHTML }}
          ></Box>
          {articleData?.ctaLink?.url && articleData?.ctaLink?.title ? (
            <Link
              sx={variant.ctaButton}
              className="ctaButton"
              href={articleData?.ctaLink?.url}
              target="_blank"
            >
              {articleData?.ctaLink?.title}
            </Link>
          ) : (
            ""
          )}
        </Box>

        <Box sx={variant.column2}>
          {pageObjectKey !== "team" && (
            <Box sx={variant.articleInfoDesktop}>{articleInfo()}</Box>
          )}
          <Box>
            {allArticlesData?.length > 1 && (
              <OtherArticles
                allArticlesData={allArticlesData}
                currentArticleId={articleData.id}
                variant={variant}
                pageObjectKey={pageObjectKey}
                title={OtherArticlesTitle()}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

var settings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 4000,
  interval: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
}
